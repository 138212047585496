import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CommentIcon from "@mui/icons-material/Comment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HistoryIcon from "@mui/icons-material/History";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ToolTip from "../common/ToolTip";
import usePermission from "../../usePermission";
import HomeIcon from "@mui/icons-material/Home";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import DownloadIcon from "@mui/icons-material/Download";
import GroupsIcon from '@mui/icons-material/Groups';
import GroupIcon from '@mui/icons-material/Group';

export default function Toolbox(props: {
  displayComments: () => void;
  displayClientFiling: () => void;
  displayAddClientFiling: () => void;
  displayAttachments: () => void;
  displayHistory: () => void;
  updateBulkStatus: () => void;
  displayDocuments: () => void;
  displayConsolidatedView: () => void;
  // displayClientSpecificConsolidatedView: () => void;
  downloadData: () => void;
  downloadDataHistory: () => void;
  editMasterFilling: () => void;
  addMasterFilling: () => void;
  // deleteMasterRecord: () => void;
  deleteRecord: () => void;
  deleteClientFiling: () => void;
  isMasterFiling: boolean;
  isTabValue: boolean;
  selectedClientId: any;
  isDisabled: boolean;
  hasTrackNowAccess: boolean;
  hasPostAwaradAccess: boolean;


}) {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);

  const showCusotmerForm = () => {
    navigate("/customer");
  };

  const displayClientFiling = () => {
    props.displayClientFiling();
  };

  const displayAddClientFiling = () => {
    props.displayAddClientFiling();
  };

  const displayComments = () => {
    props.displayComments();
  };

  const displayAttachments = () => {
    props.displayAttachments();
  };

  const displayHistory = () => {
    props.displayHistory();
  };
  const updateBulkStatus = () => {
    props.updateBulkStatus();
  };

  const displayDocuments = () => {
    props.displayDocuments();
  };

  const displayConsolidatedView = () => {
    props.displayConsolidatedView();
  };
  // const displayClientSpecificConsolidatedView = () => {
  //   props.displayClientSpecificConsolidatedView();
  // };

  const downloadData = () => {
    props.downloadData();
  };
  const downloadDataHistory = () => {
    props.downloadDataHistory();
  };

  const editFilingMaster = () => {
    props.editMasterFilling();
  };

  const showFilingMasterForm = () => {
    props.addMasterFilling();
  };

  const deleteClientAddedFiling = () => {
    props.deleteClientFiling();
  };

  const deleteFiling = () => {
    props.deleteRecord();
  };

  const closeAlert = () => {
    setShowAlert(() => false);
  };
  const hasChangeStatusPermission = usePermission(
    "ClientFilingList",
    "changeStatus",
  );
  const hasStaffAssignedPermission = usePermission(
    "ClientFilingList",
    "changeStaff",

  );
  console.log("Global toolbox", "Status", hasChangeStatusPermission, "Staffs", hasStaffAssignedPermission);

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <nav aria-label="main mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <FilterListIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayAddClientFiling}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "addFiling",
                    props.selectedClientId
                  )
                }
              >
                <ListItemIcon>
                  <ToolTip title="Deferred Filing">
                    <PersonAddAlt1Icon />
                  </ToolTip>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem disablePadding>
              {/* <ListItemButton
                onClick={displayClientFiling}
                disabled={
                  !usePermission("ClientFilingList", "viewFilingTracking")
                }
              >
                <ToolTip title="View Filings">
                  <ViewListIcon />
                </ToolTip>
              </ListItemButton> */}
              <ListItemButton disabled={props.isDisabled} onClick={displayClientFiling}>
                <ToolTip title="Home">
                  <HomeIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={showFilingMasterForm}
                // disabled={
                //   !usePermission(
                //     "ClientFilingList",
                //     "clientSpecificFiling",
                //     props.selectedClientId
                //   ) || props.isDisabled
                // }
                disabled={
                  (!props.hasTrackNowAccess && !props.hasPostAwaradAccess) || !props.selectedClientId
                }
              >
                <ToolTip title="Add Client Filing Master">
                  <PostAddIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={editFilingMaster}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "clientSpecificFiling",
                    props.selectedClientId
                  ) || props.isTabValue
                }
              >
                <ToolTip title="Edit Client Filing Master">
                  <EditIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={deleteClientAddedFiling}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "deleteClientSpecificFiling",
                    props.selectedClientId
                  ) || props.isTabValue
                }
              >
                <ToolTip title="Delete Client Filing">
                  <DeleteIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayComments}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "comments",
                    props.selectedClientId
                  )
                }
              >
                <ToolTip title="Comments">
                  <CommentIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayAttachments}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "viewFinalAttachment",
                    props.selectedClientId
                  )
                }
              >
                <ToolTip title="Final Attachment">
                  <AttachFileIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayHistory}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "activityHistory",
                    props.selectedClientId
                  )
                }
              >
                <ToolTip title="Activity History">
                  <HistoryIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayDocuments}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "masterFilingRefDoc",
                    props.selectedClientId
                  )
                }
              >
                <ToolTip title="Reference Documents">
                  <SnippetFolderIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={updateBulkStatus}
                disabled={
                  // !usePermission(
                  //   "ClientFilingList",
                  //   "changeStatus",
                  //   props.selectedClientId
                  // )
                  !hasChangeStatusPermission && !hasStaffAssignedPermission && !props.selectedClientId
                }
              >
                <ToolTip title="Bulk Update">
                  <EditNoteRoundedIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayConsolidatedView}
                disabled={
                  // !usePermission(
                  //   "ClientFilingList",
                  //   "viewConsolidated",
                  //   props.selectedClientId
                  // )
                  !hasChangeStatusPermission && !hasStaffAssignedPermission && !props.selectedClientId
                }

              >
                <ToolTip title="Consolidated update">
                  <GroupsIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton
                onClick={displayClientSpecificConsolidatedView}
                disabled={
                  !usePermission(
                    "ClientFilingList",
                    "viewConsolidated",
                    props.selectedClientId
                  )
                }
              >
                <ToolTip title="Consolidated client specific filing update">
                  <GroupIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem> */}
            {props.isTabValue ? (
              <ListItem disablePadding>
                <ListItemButton onClick={downloadDataHistory}>
                  <ToolTip title="Download">
                    <DownloadIcon />
                  </ToolTip>
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem disablePadding>
                <ListItemButton disabled={!props.selectedClientId}
                  onClick={downloadData}>
                  <ToolTip title="Download">
                    <DownloadIcon />
                  </ToolTip>
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton
                onClick={deleteFiling}
                disabled={props.isTabValue}
              >
                <ToolTip title="Defer Filing">
                  <DeleteForeverIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </React.Fragment >
  );
}
