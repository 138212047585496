import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Container } from "@mui/system";
import { useEffect } from "react";
import "./Reminder.css";
import TextField from "@mui/material/TextField";
import { useState, useRef, useContext } from "react";
import CustomerContext from "../../context/customer/CustomerContext";
import AuthContext from "../../context/auth/AuthContext";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import axios from "axios";
import useDialog from "../common/UseDialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Loader from "../common/Loader";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import UserRoleToolbox from "./UserRoleToolbox";
import UserRoleSetupHistory from "./UserRoleSetupHistory";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function Reminder() {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const { authUser, roleType } = authContext;

  const customerContext = useContext(CustomerContext);
  const { UserList, setUserList } = customerContext;

  const [isSave, setIsSave] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState();
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({})
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedUserRow, setSelectedUserRow] = useState(null)
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });

  const [filters, setFilters] = useState({
    userName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    loginId: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    clientName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    roleName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    notificationMail: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });

  const EditButton = ({ rowData }) => {
    return (
      <IconButton color="primary">
        <EditIcon onClick={() => editUserRole(rowData)} />
      </IconButton>
    );
  };

  const editUserRole = (data) => {
    // console.log("editUserRole", data);
    setIsSave(false);
    setSelectedUserRole(data);
    setFormValues({
      userName: data.userName,
      loginEmail: data.loginId,
      client:
        data.customerId > 0
          ? { code: data.customerId, name: data.customerName }
          : undefined,
      includeNotification: data.notificationMail,
      role: data.roleId ? { code: data.roleId, name: data.roleName } : "",
    });
  };

  const DeleteButton = ({ rowData }) => {
    return (
      <IconButton color="primary">
        <DeleteIcon onClick={() => handleDelete(rowData)} />
      </IconButton>
    );
  };

  const handleConfigItemChange = (event) => {
    console.log("handleConfigItemChange", event);
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormValues({
      userName: "",
      client: null,
      loginEmail: "",
      includeNotification: "",
      role: "",
    });
    setIsSave(true);
    setIsFormSubmitted(false);
    setRecordsInView(0)
    setIsLoadingDataTable(true);
    setUserRoleList([]);
    setRefreshRecordList(true)
  };

  const handleSave = async () => {
    setIsLoading(true);
    setIsFormSubmitted(true);
    // console.log("handleSave formVal", formValues);
    try {
      let hasRequired =
        !formValues.userName ||
        !formValues.loginEmail ||
        // !formValues.includeNotification||
        // !formValues.includeNotification === "" ||
        typeof formValues.includeNotification !== "boolean" ||
        !formValues.role ||
        (["JSIUser", "JSIPOC", "JSISME", "JSIReadOnly", "JSIUser-PostAward", "JSIPOC-PostAward", "JSISME-PostAward", "JSIReadOnly-PostAward"].includes(formValues.role?.name) && !formValues.loginEmail.endsWith("@jsitel.com")) ||
        (isClientRole() && !formValues.client.code);

      // console.log("isExist1", hasRequired, formValues);
      let client = formValues.client ? formValues.client.name : null;
      let isExist = userRoleList.filter((ele) => {
        // console.log("isExist", ele);
        if (formValues.loginEmail === ele.loginId) {
          return ele;
        }
      });

      // console.log("isExist", formValues, isExist);

      if (
        (formValues.role.name === "ClientAdmin" ||
          formValues.role.name === "ClientUser" ||
          formValues.role.name === "JSIPOC" ||
          formValues.role.name === "ClientAdmin-PostAward" ||
          formValues.role.name === "ClientUser-PostAward" ||
          formValues.role.name === "JSIPOC-PostAward") &&
        formValues.client === null
      ) {
        // console.log("handleUpdate Client name is required", formValues);
        return;
      }

      if (isExist.length > 0 && !hasRequired) {
        createUsersRoles(isExist[0].userId);
        return;
      }

      if (!hasRequired) {
        let payload = {
          userId: 0,
          userName: formValues.userName,
          loginId: formValues.loginEmail,
          notificationMail: formValues.includeNotification,
          customerId: formValues.client ? formValues.client.code : null,
          createDate: new Date(Date.now()).toISOString(),
          createUser: authUser.userId + "",
        };

        // console.log("handleSave payload", payload);
        let res = await axios.post(
          `${API_BASE_URL}/Application/CreateUsers`,
          payload
        );
        // console.log("res", res.data);
        if (res.data.status === "Failure") {
          setAlertMessage(res.data.errorMessage);
          setShowAlert(true);
        } else {
          createUsersRoles(res.data.data.userId);
        }
      }
    } catch (error) {
      // console.log("hasRequired", formValues);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createUsersRoles = async (userId) => {
    // console.log("createUsersRoles userId", userId);
    try {
      let payload = {
        userId: userId,
        roleId: formValues.role ? formValues.role.code : null,
        customerId: formValues.client ? formValues.client.code : null,
        notificationMail: formValues.includeNotification,
        createDate: new Date(Date.now()).toISOString(),
        createUser: authUser.userId + "",
      };
      console.log("createUsersRoles payload", payload);
      let res = await axios.post(
        `${API_BASE_URL}/Application/CreateUsersRoles`,
        payload
      );
      console.log("res", res);
      if (res.data.status === "Failure") {
        setAlertMessage(res.data.errorMessage);
        setShowAlert(true);
      } else {
        setAlertMessage("User role successfully created");
        setShowAlert(true);
        setIsFormSubmitted(false);
        // getConfigs();
        handleReset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    setIsFormSubmitted(true);
    console.log("handleUpdate formVal", formValues, selectedUserRole);
    try {
      function checkisOtherFieldChange(formValues, selectedUserRoles) {
        let isOtherFieldChange = false;
        let isNotificationChange = false

        
        // Treat undefined and "" as equivalent
        if (!((formValues?.client?.name === undefined && selectedUserRoles.clientName === "") ||
          (formValues?.client?.name === selectedUserRoles?.clientName))) {
          isOtherFieldChange = true;
        }

        if (formValues.includeNotification !== selectedUserRoles.notificationMail) {
          isNotificationChange = true;
        }

        if (formValues.role?.code !== selectedUserRoles.roleId) {
          isOtherFieldChange = true;
        }
        if (formValues.userName !== selectedUserRoles.userName) {
          isOtherFieldChange = true;
        }
        if (formValues.loginEmail !== selectedUserRoles.loginId) {
          isOtherFieldChange = true;
        }

        return !isOtherFieldChange && isNotificationChange;
      }

      // Example usage

      const isOnlyNotificationChange = await checkisOtherFieldChange(formValues, selectedUserRole);

      // Outputs: true or false
      console.log("handleUpdate isOnlyNotificationChange", isOnlyNotificationChange);

      let hasRequired =
        !formValues.userName ||
        !formValues.loginEmail ||
        !(
          formValues.includeNotification === false ||
          formValues.includeNotification === true
        ) ||
        (["JSIUser", "JSIPOC", "JSISME", "JSIReadOnly", "JSIUser-PostAward", "JSIPOC-PostAward", "JSISME-PostAward", "JSIReadOnly-PostAward"].includes(formValues.role?.name) && !formValues.loginEmail.endsWith("@jsitel.com")) ||
        !formValues.role;

      if (
        (formValues.role.name === "ClientAdmin" ||
          formValues.role.name === "ClientUser" ||
          formValues.role.name === "JSIPOC" ||
          formValues.role.name === "ClientAdmin-PostAward" ||
          formValues.role.name === "ClientUser-PostAward" ||
          formValues.role.name === "JSIPOC-PostAward") &&
        formValues.client === null
      ) {
        console.log("handleUpdate Client name is required", formValues);
        return;
      }

      if (!hasRequired) {
        let resUpdateUser = null
        if (!isOnlyNotificationChange) {
          let payload = {
            userId: selectedUserRole.userId,
            userName: formValues.userName,
            loginId: formValues.loginEmail,
            //notificationMail: formValues.includeNotification,
            customerId: formValues.client ? formValues.client.code : null,
            updateDate: new Date(Date.now()).toISOString(),
            updateUser: authUser.userId + "",
          };

          console.log("handleUpdate payload", payload);
          resUpdateUser = await axios.put(
            `${API_BASE_URL}/Application/UpdateUsers/${selectedUserRole.userId}`,
            payload
          );
          console.log("res", resUpdateUser.data);
        }
        console.log("handleUpdate res", selectedUserRole, formValues);
        if (
          selectedUserRole.roleId !== formValues.role?.code ||
          (formValues.client === undefined && selectedUserRole.customerId) ||
          (formValues.client !== undefined &&
            selectedUserRole.customerId !== formValues.client?.code) || (formValues.includeNotification !== selectedUserRole.notificationMail)
        ) {
          updateUsersRoles();
        } else {
          setAlertMessage("User role successfully updated");
          setShowAlert(true);
          setFormValues({
            userName: "",
            client: null,
            loginEmail: "",
            includeNotification: "",
            role: "",
          });
          setIsSave(true);
          setIsFormSubmitted(false);

          const vScroll = dt.current.getVirtualScroller()
          let range = vScroll.getRenderedRange();
          console.log("refreshTable vScroll", vScroll, range);

          let pageInView
          let noOfPages
          if (userRoleList.length > 100) {
            pageInView = recordsInView - 2
            noOfPages = 2
          } else {
            pageInView = recordsInView - 1
            noOfPages = 1
          }

          setRecordsInView(pageInView)
          setRefreshDetails({
            viewport: range.viewport,
            pageInView,
            noOfPages,
          })
          setIsLoadingDataTable(true);
          setUserRoleList([]);
          setRefreshRecordList(true)

        }
      }
    } catch (error) {
      console.error("errorUPD", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUsersRoles = async (userId) => {
    console.log("updateUsersRoles userId", selectedUserRole);
    try {
      let payload = {
        ...selectedUserRole,
        userId: selectedUserRole.userId,
        roleId: formValues.role ? formValues.role.code : null,
        customerId: formValues.client ? formValues.client.code : null,
        updateDate: new Date(Date.now()).toISOString(),
        updateUser: authUser.userId + "",
        notificationMail: formValues.includeNotification,
      };
      console.log("updateUsersRoles payload", payload);
      let res = await axios.put(
        `${API_BASE_URL}/Application/UpdateUsersRoles/${selectedUserRole.userRoleId}`,
        payload
      );
      console.log("res", res);
      if (res.data.status === "Failure") {
        setAlertMessage(res.data.errorMessage);
        setShowAlert(true);
      } else {
        setAlertMessage("User role successfully updated");
        setShowAlert(true);
        setFormValues({
          userName: "",
          client: null,
          loginEmail: "",
          includeNotification: "",
          role: "",
        });
        setIsSave(true);
        setIsFormSubmitted(false);

        const vScroll = dt.current.getVirtualScroller()
        let range = vScroll.getRenderedRange();
        console.log("refreshTable vScroll", vScroll, range);

        let pageInView
        let noOfPages
        if (userRoleList.length > 100) {
          pageInView = recordsInView - 2
          noOfPages = 2
        } else {
          pageInView = recordsInView - 1
          noOfPages = 1
        }

        setRecordsInView(pageInView)
        setRefreshDetails({
          viewport: range.viewport,
          pageInView,
          noOfPages,
        })
        setIsLoadingDataTable(true);
        setUserRoleList([]);
        setRefreshRecordList(true)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async () => {
    setIsLoadingDataTable(true);
    console.log("deleteUser data", selectedUser);
    try {
      let res = await axios.delete(
        `${API_BASE_URL}/Application/DeleteUsers/${selectedUser.userId}`
      );
      console.log("res", res.data);
      if (res.data.status === "Success") {
        deleteUserRole(selectedUser.userRoleId);
      } else if (res.data.status === "Failure") {
        console.log("Stats#", res.data.errorMessage);
        setAlertMessage(
          `Unable to delete this ${selectedUser.userName}. The ${selectedUser.userName} is assigned as a default approver in Approval Config. page. If you want to delete, please change the approver.`
        );
        setShowAlert(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDataTable(false);
    }
  };

  const deleteUserRole = async () => {
    console.log("deleteUserRole userRoleId", selectedUser.userRoleId);
    try {
      let res = await axios.delete(
        `${API_BASE_URL}/Application/DeleteUsersRoles/${selectedUser.userRoleId}`
      );
      console.log("res", res);
      if (res) {
        setAlertMessage("User role successfully deleted");
        setShowAlert(true);

        const vScroll = dt.current.getVirtualScroller()
        let range = vScroll.getRenderedRange();
        console.log("refreshTable vScroll", vScroll, range);

        let pageInView
        let noOfPages
        if (userRoleList.length > 100) {
          pageInView = recordsInView - 2
          noOfPages = 2
        } else {
          pageInView = recordsInView - 1
          noOfPages = 1
        }

        setRecordsInView(pageInView)
        setRefreshDetails({
          viewport: range.viewport,
          pageInView,
          noOfPages,
        })
        setIsLoadingDataTable(true);
        setUserRoleList([]);
        setRefreshRecordList(true)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { AlertDialog: ConfirmDialog, showDialog } = useDialog({
    onSubmit: deleteUserRole,
  });

  const handleDelete = (data) => {
    console.log("handleDelete data", data);
    setSelectedUser(data);
    showDialog(
      "Confirmation",
      "Are you sure you want to delete this user?",
      "confirm"
    );
  };

  const getClientName = (clientId) => {
    console.log("getClientName", clientId, customerList);
    let findName = customerList.filter((ele) => ele.code === clientId);
    console.log("getClientName", findName);
    return findName.length > 0 ? findName[0].name : "";
  };

  const cellClientName = (rowData) => {
    console.log("cellClientName", rowData);
    return getClientName(rowData.customerId);
  };

  const footerContent = (
    <div>
      {/* <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      /> */}
      <Button
        onClick={() => closeAlert()}
        autoFocus
        variant="contained"
        sx={{ backgroundColor: "#6366F1", color: "#ffffff" }}
        size="medium"
      >
        Ok
      </Button>
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
  };

  const isClientRole = () => {
    console.log("isClientRole", formValues);
    return (
      formValues.role &&
      formValues.role.name &&
      (formValues.role.name === "ClientAdmin" ||
        formValues.role.name === "ClientUser" ||
        formValues.role.name === "JSIPOC" ||
        formValues.role.name === "ClientAdmin-PostAward" ||
        formValues.role.name === "ClientUser-PostAward" ||
        formValues.role.name === "JSIPOC-PostAward" ||
        formValues.role.name === "ClientReadOnly" ||
        formValues.role.name === "ClientReadOnly-PostAward")
    );
  };

  const isAdminRole = () => {
    console.log("isAdminRole", formValues);
    return (
      formValues.role &&
      formValues.role.name &&
      (formValues.role.name !== "Admin" ||
        formValues.role.name !== "Admin-PostAward")
    );
  };

  useEffect(() => {
    console.log("isAdminRole changed", isAdminRole(), formValues);
    if (!isAdminRole()) {
      console.log("isAdminRole clear client");
      setFormValues((prevValues) => ({
        ...prevValues,
        client: null,
      }));
    }
  }, [formValues.role]);

  const getConfigs = async () => {
    setIsLoadingDataTable(true);

    console.log("@@Fetch getConfigs");
    // try {
    //   const roleListUser = await axios.get(
    //     `${API_BASE_URL}/Application/ListUsersRoles`
    //   );
    //   console.log("getConfigs res", roleListUser.data.data);
    //   let list = roleListUser.data.data.map((ele) => ({
    //     ...ele,
    //     clientName: getClientName(ele.customerId),
    //   }));
    //   // setUserRoleList(list);
    // } catch (error) {
    //   console.log("@@getConfigs Error:", error);
    // }
    try {
      let roles = await axios.get(`${API_BASE_URL}/Application/ListRoles`);
      console.log("getConfigs res", roles.data.data);
      roles = roles.data.data.map((ele) => ({
        name: ele.roleName,
        code: ele.roleId,
      }));
      setRoleList(roles);
    } catch (error) {
      console.log("@@getConfigs Error:", error);
    } finally {
      setIsLoadingDataTable(false);
    }
  };

  useEffect(() => {
    console.log("customerList", customerList);
    if (customerList.length > 0) {
      getConfigs();
      setRecordsInView(0)
      setIsLoadingDataTable(true);
      setUserRoleList([]);
      setIsFilterChange([])
      setSelectedFilterValues({})
      setRefreshRecordList(true)
    }
  }, [customerList]);

  const getCustomerList = async () => {
    try {
      let res = await axios.get(`${API_BASE_URL}/Customer/CustomerList`);
      console.log("setcustomerList res", res.data);
      let _data;
      if (
        !(roleType.includes("Admin") || roleType.includes("Admin-PostAward"))
      ) {
        _data = res.data
          .filter((ele) => authUser.clients.includes(ele.customerId))
          .map((ele) => ({
            code: ele.customerId,
            name: ele.customerName,
          }));
      } else {
        _data = res.data.map((ele) => ({
          code: ele.customerId,
          name: ele.customerName,
        }));
      }

      _data = _data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      console.log("setcustomerList", _data);
      setCustomerList(_data);
    } catch (error) {
      console.log(error);
    }
  };

  const displayWarning = async () => {
    console.log("createUsersRoles ");
    try {
      let res = await axios.get(
        `${API_BASE_URL}/Customer/ListCustomersWithNullJSIPoc`
      );
      console.log("res", res);
      if (res.data.status === "Failure") {
        setAlertMessage(res.data.errorMessage);
        setShowAlert(true);
      } else {
        setAlertMessage({ warningDisplay: true, data: res.data });
        setShowAlert(true);
      }
    } catch (error) {
      console.lo(error);
    }
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const itemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <span>{option}</span>
      </div>
    );
  };

  const notificationArray = ["True", "False"];

  const notificationRowFilterTemplate = (options) => {
    // console.log("notificationRowFilterTemplate:", options, selectedFilterValues);
    if (!selectedFilterValues.notification &&
      options?.filterModel?.value) {
      // console.log("notificationRowFilterTemplate filterModel", options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={selectedFilterValues.notification || []}
        options={notificationArray}
        itemTemplate={itemTemplate}
        onChange={(e) => {
          setSelectedFilterValues((prev) => {
            return { ...prev, notification: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder='Any'
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const renderDropdownItem = (option) => {
    const { name } = option;
    if (name.length > 65) {
      return (
        <Tooltip title={name} key={name}>
          <span>{name.substr(0, 65)}...</span>
        </Tooltip>
      );
    }
    return <span key={name}>{name}</span>;
  };

  const dt = useRef(null);
  const pageSize = 100;

  // lazy load function
  const loadDataLazy = async (event) => {
    console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback
    const vScroll = dt.current.getVirtualScroller()

    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    let tableList = userRoleList
    console.log(
      "loadDataLazy totalRecords",
      firstVal,
      totalRecords,
      lazyLoading,
      tableList.length,
    );

    console.log("loadDataLazy", firstVal < totalRecords, first > tableList.length - 10, !lazyLoading)

    if (firstVal < totalRecords && first > tableList.length - 10 && !lazyLoading) {
      //scroll down
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      console.log("loadDataLazy d record", records);
      if (records) {
        console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoadingDataTable(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(15);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoadingDataTable(false);
        }
      }
    }
  };

  const loadRecordsList = async (first, last, direction) => {
    // console.log("loadRecordsList props", first, last, direction);
    setIsLoadingDataTable(true)
    let url = `${API_BASE_URL}/Application/ListUsersRolesByPagination?StartRow=${first + 1}&EndRow=${last}`

    // console.log("loadRecordsList fliter/sort", isFilterChange, sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
          }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      console.log("getSortData", sortParameter)
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending";

      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      console.log("loadRecordsList res", res);

      // console.log("loadRecordsList res", res.data, res.data.items);

      if (res.data) {
        setIsLoadingDataTable(true);
        let ogList = userRoleList.map((ele) => ele)
        await modifyList(res.data.items, direction, ogList);
        setTotalRecords(res.data.totalItems);

        return res.data.items;
      } else {
        return false;
      }
    } catch (err) {
      // console.log("loadRecordsList err", err);
    }
  };


  //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    console.log("modifyList", list, direction);
    if (list.length > 0) {
      //list has data
      const updList = list.map((ele, i) => ({
        ...ele,
        clientName: getClientName(ele.customerId),
        userListId: `${i}${ele.userRoleId}`,
      }));

      let tableList = userRoleList
      console.log("modifyList updCustomers", updList, tableList);

      let updUserList;
      if (direction === "down") {
        //scroll down
        if (tableList.length >= 200) {
          //list > 200

          //slice 2 half of list
          let unloadList = ogList.slice(
            ogList.length - pageSize,
            ogList.length
          );
          // console.log("modifyList dw unloadList", unloadList);

          //add new data
          updUserList = [...unloadList, ...updList];
          // console.log("modifyList dw updUserList", updUserList);
        } else {
          //list < 200

          // add new data to list
          updUserList = [...ogList, ...updList];
          // console.log("modifyList dw updUserList", updUserList);
        }
      } else if (direction === "up") {
        //scroll up
        if (tableList.length >= 200) {
          //list > 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updUserList = [...updList, ...unloadList];
          // console.log("modifyList up updUserList", updUserList);
        } else {
          //list < 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          console.log("modifyList up unloadList", unloadList);

          //add new data
          updUserList = [...updList, ...unloadList];
          console.log("modifyList up updUserList", updUserList);
        }
      }

      setUserRoleList(() => updUserList)
      setUserList(updUserList)
      setRefreshRecordList(false)
      setLazyLoading(false)
      setIsLoadingDataTable(false);
      return updUserList
    } else {
      //list empty
      // console.log("modifyList empty",list)
      setRefreshRecordList(false)
      setLazyLoading(false)
      setIsLoadingDataTable(false);
      setUserList([])
      return [];
    }
  };

  const onFilterChange = async (e) => {
    console.log("onFilterChange props", e.filters);
    let filterChange = [];
    //map filter event
    Object.keys(e.filters).forEach((col) => {
      // console.log("onFilterChange col", col, e.filters[col]);
      if (
        e.filters[col].constraints &&
        e.filters[col].constraints[0].value !== null
      ) {
        if (col === "loginId") {
          filterChange.push({
            name: `SearchByLoginEmail`,
            val: e.filters[col].constraints[0].value,
            searchType: `LoginEmailSearchType`,
            searchTypeVal: getSearchType(
              e.filters[col].constraints[0].matchMode
            ),
          });
        } else if (col === "notificationMail") {
          filterChange.push({
            name: `SearchByNotificationInclude`,
            val: e.filters[col].constraints[0].value,
          });
        } else {
          filterChange.push({
            name: `SearchBy${col}`,
            val: e.filters[col].constraints[0].value,
            searchType: `${col}SearchType`,
            searchTypeVal: getSearchType(
              e.filters[col].constraints[0].matchMode
            ),
          });
        }
      }
    });
    console.log("onFilterChange filterChange", filterChange);

    //set filter
    handleSetFilterVariable(filterChange);
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoadingDataTable(true);
    setUserRoleList([]);
  };

  const handleSort = async (e) => {
    console.log("handleSort props", e, e.sortField);
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setIsLoadingDataTable(true);
      setUserRoleList([]);
    }
  };


  const getSortData = (val) => {
    console.log("getSortData props", val);
    if (val === "federalCategories" || val === "stateCategories") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "BusinessCategory",
        SortOrderParameter: "SortOrder"
      }
    } else if (val === "awardType") {
      return {
        SortByParameter: "SortByAwardType",
        SortByVal: "AwardType",
        SortOrderParameter: "SortOrderAwardType"
      }
    } else if (val === "awardingAgency") {
      return {
        SortByParameter: "SortByAwardingAgency",
        SortByVal: "AwardingAgency",
        SortOrderParameter: "SortOrderAwardingAgency"
      }
    } else {
      return {
        SortByParameter: "SortBy",
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        SortOrderParameter: "SortOrder"
      }
    }
  }

  useEffect(() => {
    // console.log("filter/sort change", isFilterChange, sortingData, lazyLoading );
    //on filter/sort change
    if (!lazyLoading && !refreshRecordList) {
      setLazyLoading(true)
      const sortAndFilterApiCall = async () => {
        let records = await loadRecordsList(0, 100, "down");
        setRecordsInView(1);
        console.log("recordsCheck", records);
        setIsLoadingDataTable(false);
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  const refreshRecords = async () => {
    console.log("refreshRecords")

    let records
    console.log("refreshRecords refreshDetails", refreshDetails);
    if (refreshDetails?.pageInView || refreshDetails?.noOfPages) {
      let first = refreshDetails.pageInView * pageSize
      records = await loadRecordsList(first, first + refreshDetails?.noOfPages * pageSize, "down");
    } else {
      records = await loadRecordsList(0, pageSize, "down");
    }

    if (records) {
      if (refreshDetails?.viewport && refreshDetails?.noOfPages) {
        console.log("refreshRecords scrollInView", refreshDetails.viewport.first, dt)
        setRecordsInView((old) => old + refreshDetails?.noOfPages);
        setRefreshDetails(null)
      } else {
        setRecordsInView((old) => old + 1);
      }
    }
  }
  const fetchFilingMasterHistory = async (userRoleId) => {
    console.log("fetchFilingMasterHistory props", userRoleId);
    try {
      let res = await axios.get(
        `${API_BASE_URL}/Application/UserRolesAudit?UserRoleId=${userRoleId}`
      );
      let history = res.data.data;

      setActivityHistory(() => history);
      console.log("fetchFilingMasterHistory res", history);
    } catch (error) {
      console.log("@@Error:", error);
    }

  };

  const displayHomePage = () => {
    navigate("/");
  };
  const displayTrackNowFilingMasterList = () => {
    navigate("/filing-master-list")
  };
  const displayPostAwardFilingMasterList = () => {
    navigate("/postaward-filing-master-list");
  };
  const displayClientFilingTrackerList = () => {
    navigate("/client-filing-tracker-list");
  };

  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };
  const displayHistory = async () => {
    console.log("displayHistory", selectedUserRow);
    if (selectedUserRow && selectedUserRow.userRoleId) {
      console.log(`Fetching History for :${selectedUserRow.userRoleId}`);
      fetchFilingMasterHistory(selectedUserRow.userRoleId);
      setShowHistory(() => true);
    } else {
      showAlert("Info", "Please Select a record", "info");
      // closeDialog();
    }
  };
  useEffect(() => {
    console.log("refreshRecordList,", refreshRecordList, !lazyLoading);
    if (refreshRecordList && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords()
    }

  }, [refreshRecordList])

  useEffect(() => {
    console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => {
    console.log("userRoleList,", userRoleList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoleList, userRoleList.length, JSON.stringify(userRoleList)]);

  useEffect(() => {
    // console.log("UserList,", UserList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserList]);

  useEffect(() => { console.log("isLoading", isLoading) }, [isLoading])

  useEffect(() => { console.log("lazyLoading", lazyLoading) }, [lazyLoading])

  const handleSelect = (e) => {
    // console.log("handleSelect e", e);
    setSelectedUserRow(e.value)
  }

  return (
    <Fragment >
      <Grid sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
        <Grid sm={24} sx={{ height: "100%", width: "100%", margin: "16px" }}>
          <div
            style={{
              borderRadius: "2%",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
              backgroundColor: "#ffffff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {isLoading && (
              <Loader />
            )}
            <React.Fragment>
              <Box sx={{ padding: "30px" }}>
                <Stack direction="column">
                  <Stack direction="row" justifyContent="space-between">
                    <div>
                      <Typography style={{ fontWeight: "bold" }}>
                        User Role Setup
                      </Typography>
                    </div>
                    <IconButton color="warning">
                      <WarningAmberIcon onClick={() => displayWarning()} />
                    </IconButton>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ marginTop: "1rem" }}
                  >
                    <div style={{ width: "50%", paddingRight: "1rem" }}>
                      <Stack direction="column" spacing={2}>
                        <Stack>
                          <Typography>User Name</Typography>
                          <TextField
                            id="userName"
                            name="userName"
                            variant="filled"
                            sx={{ paddingTop: "10px" }}
                            value={formValues.userName}
                            onChange={handleConfigItemChange}
                            required
                            error={isFormSubmitted && !formValues.userName} // show error if form submitted and field is empty
                            helperText={
                              isFormSubmitted && !formValues.userName
                                ? "User Name is required"
                                : ""
                            }
                          />
                        </Stack>

                        <Stack direction="row" spacing={3}>
                          <div style={{ width: "100%" }}>
                            <Typography>Client Name</Typography>
                            <Dropdown
                              name="client"
                              showClear
                              value={formValues.client}
                              onChange={handleConfigItemChange}
                              options={customerList}
                              optionLabel="name"
                              autoFocus
                              style={{ width: "100%", marginTop: "5px" }}
                              disabled={!isAdminRole()}
                            />
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "small",
                                marginTop: "1rem",
                                color: "red",
                                display:
                                  isFormSubmitted &&
                                    isClientRole() &&
                                    !formValues.client
                                    ? "block"
                                    : "none",
                              }}
                            >
                              Please select client
                            </Typography>
                          </div>
                        </Stack>
                      </Stack>
                    </div>

                    <div style={{ width: "50%" }}>
                      <Stack>
                        <Typography>Login Email</Typography>
                        <TextField
                          id="loginEmail"
                          name="loginEmail"
                          variant="filled"
                          sx={{ paddingTop: "10px" }}
                          value={formValues.loginEmail}
                          onChange={handleConfigItemChange}
                          required
                          error={(isFormSubmitted && !formValues.loginEmail) || isFormSubmitted && !formValues.loginEmail.endsWith("@jsitel.com") && (["JSIUser", "JSIPOC", "JSISME", "JSIReadOnly", "JSIUser-PostAward", "JSIPOC-PostAward", "JSISME-PostAward", "JSIReadOnly-PostAward"].includes(formValues.role?.name))
                          }
                          helperText={
                            isFormSubmitted && !formValues.loginEmail
                              ? "Login Email is required"
                              :
                              isFormSubmitted && !formValues.loginEmail.endsWith("@jsitel.com") && (["JSIUser", "JSIPOC", "JSISME", "JSIReadOnly", "JSIUser-PostAward", "JSIPOC-PostAward", "JSISME-PostAward", "JSIReadOnly-PostAward"].includes(formValues.role?.name))
                                ? " Please enter JSI Staff email ID for JSI roles."
                                : ""
                          }
                        />
                      </Stack>
                      <Stack direction="row" spacing={3}>
                        <div style={{ width: "100%", marginTop: "1rem" }}>
                          <Typography>Role</Typography>
                          <Dropdown
                            name="role"
                            value={formValues.role}
                            onChange={handleConfigItemChange}
                            options={roleList}
                            optionLabel="name"
                            autoFocus
                            style={{ width: "100%", marginTop: "5px" }}
                          />
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "small",
                              marginTop: "1rem",
                              color: "red",
                              display:
                                isFormSubmitted && !formValues.role
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Please select role
                          </Typography>
                        </div>
                        <div style={{ width: "100%", marginTop: "1rem" }}>
                          <Typography>Include Notification?</Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              marginTop: "1rem",
                            }}
                          >
                            <div
                              className="flex align-items-center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId="yes"
                                name="includeNotification"
                                value={true}
                                onChange={(e) => {
                                  console.log("includeNotification value", e);
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.includeNotification === true}
                              />
                              <label htmlFor="yes" style={{ marginLeft: "1rem" }}>
                                Yes
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId="no"
                                name="includeNotification"
                                value={false}
                                onChange={(e) => {
                                  console.log(
                                    "includeNotification value",
                                    e.value
                                  );
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.includeNotification === false}
                              />
                              <label htmlFor="no" style={{ marginLeft: "1rem" }}>
                                No
                              </label>
                            </div>
                          </div>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "small",
                              marginTop: "1rem",
                              color: "red",
                              display:
                                isFormSubmitted &&
                                  !(
                                    formValues.includeNotification === true ||
                                    formValues.includeNotification === false
                                  )
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Please select notification
                          </Typography>
                        </div>
                      </Stack>
                    </div>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="end"
                    sx={{ marginTop: "1rem" }}
                  >
                    <Button
                      style={{
                        color: "blue",
                        width: "100px",
                        height: "42px",
                        fontWeight: "bold",
                      }}
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    {isSave ? (
                      <Button
                        variant="contained"
                        style={{
                          color: "white",
                          width: "100px",
                          height: "42px",
                          marginLeft: "20px",
                        }}
                        //   disabled={!isFormChanged}
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        style={{
                          color: "white",
                          width: "100px",
                          height: "42px",
                          marginLeft: "20px",
                        }}
                        //   disabled={!isFormChanged}
                        onClick={handleUpdate}
                      >
                        Update
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </React.Fragment>
            <div
              className="card"
              style={{ padding: " 0 30px", marginBottom: "1rem" }}
            >
              <DataTable
                scrollable
                showGridlines
                dataKey="userListId"
                value={UserList}
                filters={filters}
                filterMode="multiple"
                selection={selectedUserRow}
                onSelectionChange={(e) => handleSelect(e)}
                loading={isLoadingDataTable}
                tableStyle={{ minWidth: "50rem", maxHeight: "20vh" }}
                ref={dt}
                virtualScrollerOptions={{
                  onScroll: loadDataLazy,
                  itemSize: 70,
                  delay: 0,
                  scrollHeight: "550px",
                  appendOnly: true,
                }}
                scrollHeight='550px'
                onFilter={(e) => onFilterChange(e)}
              // sortField={sortingData.sortBy}
              // sortOrder={sortingData.sortOrder}
              // onSort={(e) => handleSort(e)}
              >
                <Column
                  selectionMode='single'
                  //headerStyle={{ width: "3rem" }}
                  style={{ width: "1rem", margin: "0" }}
                >
                  Select
                </Column>
                <Column
                  field="userName"
                  header="User Name"
                  // sortable 
                  filter
                  filterField="userName"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterPlaceholder="Search by User Name"
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                ></Column>
                <Column
                  field="loginId"
                  header="Login Email"
                  // sortable  
                  filter
                  filterField="loginId"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterPlaceholder="Search by Login Email"
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                ></Column>
                <Column
                  field="clientName"
                  header="Client Name"
                  // sortable 
                  filter
                  filterField="clientName"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterPlaceholder="Search by Client Name"
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  body={(rowData) => (
                    <>
                      {rowData.clientName && rowData.clientName.length > 35 ? (
                        <Tooltip title={rowData.clientName} arrow>
                          <span>{rowData.clientName.substring(0, 35)}...</span>
                        </Tooltip>
                      ) : (
                        rowData.clientName
                      )}
                    </>
                  )}
                ></Column>
                <Column
                  field="roleName"
                  header="Role"
                  filter
                  filterField="roleName"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterPlaceholder="Search by Role"
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                ></Column>
                <Column
                  field="notificationMail"
                  header="Include Notification?"
                  filter
                  filterField="notificationMail"
                  showAddButton={false}
                  showFilterOperator={false}
                  showFilterMatchModes={false}
                  filterElement={notificationRowFilterTemplate}
                  onFilterMatchModeChange={() => setSelectedFilterValues({})}
                  onFilterClear={() => setSelectedFilterValues({})}
                ></Column>
                <Column
                  style={{ textAlign: "center" }}
                  body={(rowData) => (
                    <>
                      <Stack direction="row">
                        <EditButton rowData={rowData} />
                        <DeleteButton rowData={rowData} />
                      </Stack>
                    </>
                  )}
                ></Column>
              </DataTable>
            </div>
          </div>
          <ConfirmDialog />
          <Dialog
            header="Info"
            visible={showAlert}
            style={{ width: "30vw" }}
            onHide={() => closeAlert()}
            footer={footerContent}
          >
            {alertMessage.warningDisplay ? (
              <div>
                <p className="m-0">
                  JSI POC not configured for the following clients
                </p>
                <ul>
                  {alertMessage.data.map((ele) => (
                    <li>{ele}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <p className="m-0">{alertMessage}</p>
            )}
          </Dialog>
        </Grid>

        {/* <Grid sx={{
          height: "100%",
          width: "5%",
          margin: "5px"
        }}>
          <div style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}>
            <Item
              sx={{
                height: "100%",
              }}
            >
              <UserRoleToolbox
                displayHomePage={displayHomePage}
                displayTrackNowFilingMasterList={displayTrackNowFilingMasterList}
                displayPostAwardFilingMasterList={displayPostAwardFilingMasterList}
                displayClientFilingTrackerList={displayClientFilingTrackerList}
                displayHistory={displayHistory}
                handleClose={closeHistory}
              />
            </Item>
          </div>
        </Grid > */}
      </Grid>
      {/* {<UserRoleSetupHistory
        show={showHistory}
        dockAt="right"
        customerList={customerList}
        roleList={roleList}
        //tracker={selectedmasters}
        //  commentActivityHistory={commentActivityHistory}
        selectedUserRow={selectedUserRow}
        activityHistory={activityHistory}
        handleClose={closeHistory}
      />} */}
    </Fragment>
  );
}
